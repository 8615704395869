import './App.css';

import DaughterPh1 from "./daughter/1.jpg";
import DaughterPh2 from "./daughter/2.jpg";
import DaughterPh4 from "./daughter/4.jpg";
import DaughterPh6 from "./daughter/6.jpg";
import DaughterPh7 from "./daughter/7.jpg";
import DaughterPh9 from "./daughter/9.jpg";
import DaughterPh8 from "./daughter/8.jpg";
import DaughterPh15 from "./daughter/15.jpg";
import DaughterPh16 from "./daughter/16.jpg";
import DaughterPh5 from "./daughter/5.jpg";
import DaughterPh11 from "./daughter/11.jpg";
import DaughterPh12 from "./daughter/12.jpg";
import DaughterPh10 from "./daughter/10.jpg";
import DaughterPh13 from "./daughter/13.jpg";
import DaughterPh17 from "./daughter/17.jpg";
import DaughterPh18 from "./daughter/18.jpg";
import DaughterPh22 from "./daughter/22.jpg";
import DaughterPh19 from "./daughter/19.jpg";
import DaughterPh20 from "./daughter/20.jpg";
import DaughterPh21 from "./daughter/21.jpg";



function Daughter() {
    return (    
            <div className='cards-daughter-grid'>
                <div className='card d-1'>
                    <img  src={DaughterPh1} alt='мама и дочь фото'/>
                </div> 
                <div className='card d-2'>
                    <img  src={DaughterPh2} alt='мама и дочь фото'/>
                </div> 
                <div className='card d-3'>
                    <img src={DaughterPh4} alt='мама и дочь фото'/>
                </div>                
                <div className='card d-4'>
                    <img src={DaughterPh6} alt='мама и дочь фото'/>
                </div>   
                <div className='card d-5'>
                    <img src={DaughterPh7} alt='мама и дочь фото'/>
                </div>   
                <div className='card d-6'>
                    <img src={DaughterPh9} alt='мама и дочь фото'/>
                </div>   
                <div className='card d-7'>
                    <img  src={DaughterPh8} alt='мама и дочь фото'/>
                </div>   
                <div className='card d-8'>
                    <img  src={DaughterPh15} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-9'>
                    <img  src={DaughterPh16} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-10'>
                    <img  src={DaughterPh5} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-11'>
                    <img src={DaughterPh11} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-12'>
                    <img  src={DaughterPh12} alt='мама и дочь фото'/>
                </div> 
                <div className='card d-13'>
                    <img  src={DaughterPh10} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-14'>
                    <img src={DaughterPh13} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-15'>
                    <img  src={DaughterPh17} alt='мама и дочь фото'/>
                </div> 
                <div className='card d-16'>
                    <img src={DaughterPh18} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-17'>
                    <img  src={DaughterPh22} alt='мама и дочь фото'/>
                </div> 
                <div className='card d-18'>
                    <img  src={DaughterPh19} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-19'>
                    <img src={DaughterPh20} alt='мама и дочь фото'/>
                </div>  
                <div className='card d-20'>
                    <img src={DaughterPh21} alt='мама и дочь фото'/>
                </div> 
        </div>
   
    ) 

}

export default Daughter;