import './App.css';

import birthPh1 from "./birthday/1.jpg";
import birthPh2 from "./birthday/2.jpg";
import birthPh3 from "./birthday/3.jpg";
import birthPh4 from "./birthday/4.jpg";
import birthPh5 from "./birthday/5.jpg";
import birthPh6 from "./birthday/6.jpg";
import birthPh9 from "./birthday/9.jpg";
import birthPh11 from "./birthday/11.jpg";
import birthPh10 from "./birthday/10.jpg";
import birthPh14 from "./birthday/14.jpg";
import birthPh12 from "./birthday/12.jpg";
import birthPh13 from "./birthday/13.jpg";
import birthPh15 from "./birthday/15.jpg";
import birthPh20 from "./birthday/20.jpg";
import birthPh17 from "./birthday/17.jpg";
import birthPh18 from "./birthday/18.jpg";
import birthPh19 from "./birthday/19.jpg";
import birthPh22 from "./birthday/22.jpg";
import birthPh23 from "./birthday/23.jpg";
import birthPh21 from "./birthday/21.jpg";
import birthPh24 from "./birthday/24.jpg";


function Birthday() {
    return (
        <div>
            <div className='cards-birth-grid'>
                <div className='card b-1'>
                    <img  src={birthPh1} alt='детский день рождения фото'/>
                </div>
                <div className='card b-2'>
                    <img  src={birthPh2} alt='детский день рождения фото'/>
                </div>
                <div className='card b-3'>
                    <img  src={birthPh3} alt='детский день рождения фото'/>
                </div>
                <div className='card b-4'>
                    <img  src={birthPh4} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-5'>
                    <img src={birthPh5} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-6'>
                    <img src={birthPh6} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-7'>
                    <img src={birthPh9} alt='детский день рождения фото'/>
                </div>
                <div className='card b-8'>
                    <img src={birthPh11} alt='детский день рождения фото'/>
                </div>
                <div className='card b-9'>
                    <img  src={birthPh10} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-10'>
                    <img  src={birthPh14} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-11'>
                    <img  src={birthPh12} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-12'>
                    <img  src={birthPh13} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-13'>
                    <img  src={birthPh24} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-14'>
                    <img  src={birthPh15} alt='детский день рождения фото'/>
                </div> 

                <div className='card b-15'>
                    <img  src={birthPh17} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-16'>
                    <img  src={birthPh18} alt='детский день рождения фото'/>
                </div>
                <div className='card b-17'>
                    <img  src={birthPh19} alt='детский день рождения фото'/>
                </div>
                <div className='card b-18'>
                    <img  src={birthPh20} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-19'>
                    <img  src={birthPh22} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-20'>
                    <img  src={birthPh23} alt='детский день рождения фото'/>
                </div> 
                <div className='card b-21'>
                    <img  src={birthPh21} alt='детский день рождения фото'/>
                </div> 
            </div>
        </div>
    )     
}

export default Birthday;