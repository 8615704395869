import './App.css';

import NewbornPh1 from "./newborn/1.jpg";
import NewbornPh2 from "./newborn/2.jpg";
import NewbornPh6 from "./newborn/6.jpg";
import NewbornPh3 from "./newborn/3.jpg";
import NewbornPh4 from "./newborn/4.jpg";
import NewbornPh5 from "./newborn/5.jpg";
import NewbornPh8 from "./newborn/8.jpg";
import NewbornPh11 from "./newborn/11.jpg";
import NewbornPh16 from "./newborn/16.jpg";
import NewbornPh10 from "./newborn/10.jpg";
import NewbornPh14 from "./newborn/14.jpg";
import NewbornPh12 from "./newborn/12.jpg";
import NewbornPh13 from "./newborn/13.jpg";
import NewbornPh15 from "./newborn/15.jpg";
import NewbornPh17 from "./newborn/17.jpg";
import NewbornPh23 from "./newborn/23.jpg";
import NewbornPh21 from "./newborn/21.jpg";
import NewbornPh25 from "./newborn/25.jpg";
import NewbornPh24 from "./newborn/24.jpg";
import NewbornPh22 from "./newborn/22.jpg";
import NewbornPh19 from "./newborn/19.jpg";
import NewbornPh27 from "./newborn/27.jpg";
import NewbornPh28 from "./newborn/28.jpg";
import NewbornPh26 from "./newborn/26.jpg";



function Newborn() {
    return (    
            <div className='cards-newborn-grid'>
                <div className='card n-1'>
                    <img  src={NewbornPh1} alt='новорожденный фото'/>
                </div>
                <div className='card n-2'>
                    <img  src={NewbornPh2} alt='новорожденный фото'/>
                </div>   
                <div className='card n-3'>
                    <img  src={NewbornPh6} alt='новорожденный фото'/>
                </div>   
                <div className='card n-4'>
                    <img  src={NewbornPh3} alt='новорожденный фото'/>
                </div>
                <div className='card n-5'>
                    <img src={NewbornPh4} alt='новорожденный фото'/>
                </div>   
                <div className='card n-6'>
                    <img  src={NewbornPh5} alt='новорожденный фото'/>
                </div>   
                <div className='card n-7'>
                    <img  src={NewbornPh8} alt='новорожденный фото'/>
                </div>   
                <div className='card n-8'>
                    <img  src={NewbornPh11} alt='новорожденный фото'/>
                </div>
                <div className='card n-9'>
                    <img  src={NewbornPh16} alt='новорожденный фото'/>
                </div>
                <div className='card n-10'>
                    <img  src={NewbornPh10} alt='новорожденный фото'/>
                </div>
                <div className='card n-11'>
                    <img  src={NewbornPh14} alt='новорожденный фото'/>
                </div>
                <div className='card n-12'>
                    <img  src={NewbornPh12} alt='новорожденный фото'/>
                </div>
                <div className='card n-13'>
                    <img  src={NewbornPh13} alt='новорожденный фото'/>
                </div>   
                <div className='card n-14'>
                    <img  src={NewbornPh15} alt='новорожденный фото'/>
                </div>
                <div className='card n-15'>
                    <img  src={NewbornPh17} alt='новорожденный фото'/>
                </div>
                <div className='card n-16'>
                    <img  src={NewbornPh23} alt='новорожденный фото'/>
                </div>
                <div className='card n-17'>
                    <img  src={NewbornPh21} alt='новорожденный фото'/>
                </div>
                <div className='card n-18'>
                    <img className='ph3' src={NewbornPh25} alt='новорожденный фото'/>
                </div>           
                <div className='card n-19'>
                    <img  src={NewbornPh24} alt='новорожденный фото'/>
                </div>
                <div className='card n-20'>
                    <img  src={NewbornPh22} alt='новорожденный фото'/>
                </div>
                <div className='card n-21'>
                    <img src={NewbornPh19} alt='новорожденный фото'/>
                </div>
                <div className='card n-22'>
                    <img  src={NewbornPh27} alt='новорожденный фото'/>
                </div>
                <div className='card n-23'>
                    <img src={NewbornPh28} alt='новорожденный фото'/>
                </div>
                <div className='card n-24'>
                    <img  src={NewbornPh26} alt='новорожденный фото'/>
                </div>
        </div>
   
    ) 

}

export default Newborn;