import './App.css';

import womenPh58 from "./women/58.jpg";
import womenPh59 from "./women/59.jpg";
import womenPh60 from "./women/60.jpg";
import womenPh43 from "./women/43.jpg";
import womenPh3 from "./women/3.jpg";
import womenPh1 from "./women/1.jpg";
import womenPh41 from "./women/41.jpg";
import womenPh6 from "./women/6.jpg";
import womenPh5 from "./women/5.jpg";
import womenPh4 from "./women/4.jpg";
import womenPh42 from "./women/42.jpg";
import womenPh8 from "./women/8.jpg";
import womenPh9 from "./women/9.jpg";
import womenPh10 from "./women/10.jpg";
import womenPh11 from "./women/11.jpg";
import womenPh7 from "./women/7.jpg";
import womenPh12 from "./women/12.jpg";
import womenPh38 from "./women/38.jpg";
import womenPh39 from "./women/39.jpg";
import womenPh40 from "./women/40.jpg";
import womenPh13 from "./women/13.jpg";
import womenPh14 from "./women/14.jpg";
import womenPh15 from "./women/15.jpg";
import womenPh16 from "./women/16.jpg";
import womenPh17 from "./women/17.jpg";
import womenPh22 from "./women/22.jpg";
import womenPh24 from "./women/24.jpg";
import womenPh55 from "./women/55.jpg";
import womenPh27 from "./women/27.jpg";
import womenPh23 from "./women/23.jpg";
import womenPh25 from "./women/25.jpg";
import womenPh26 from "./women/26.jpg";
import womenPh29 from "./women/29.jpg";
import womenPh32 from "./women/32.jpg";
import womenPh33 from "./women/33.jpg";
import womenPh30 from "./women/30.jpg";
import womenPh34 from "./women/34.jpg";
import womenPh35 from "./women/35.jpg";
import womenPh51 from "./women/51.jpg";
import womenPh19 from "./women/19.jpg";
import womenPh52 from "./women/52.jpg";
import womenPh44 from "./women/44.jpg";
import womenPh50 from "./women/50.jpg";
import womenPh18 from "./women/18.jpg";
import womenPh46 from "./women/46.jpg";

import womenPh20 from "./women/20.jpg";
import womenPh21 from "./women/21.jpg";
import womenPh54 from "./women/54.jpg";
import womenPh57 from "./women/57.jpg";
import womenPh2 from "./women/2.jpg";
import womenPh53 from "./women/53.jpg";
import womenPh37 from "./women/37.jpg";





function Women() {
    return (
    
            <div className='cards-women-grid'>
                <div className='card wm-1'>
                    <img  src={womenPh58} alt='девушки фото'/>
                </div>
                <div className='card wm-2'>
                    <img  src={womenPh59} alt='девушки фото'/>
                </div>
                <div className='card wm-3'>
                    <img  src={womenPh60} alt='девушки фото'/>
                </div>
                <div className='card wm-4'>
                    <img  src={womenPh43} alt='девушки фото'/>
                </div>
                <div className='card wm-5'>
                    <img  src={womenPh3} alt='девушки фото'/>
                </div>
                <div className='card wm-6'>
                    <img  src={womenPh1} alt='девушки фото'/>
                </div>
                <div className='card wm-7'>
                    <img  src={womenPh41} alt='девушки фото'/>
                </div>
                <div className='card wm-8'>
                    <img  src={womenPh6} alt='девушки фото'/>
                </div>
                <div className='card wm-9'>
                    <img  src={womenPh5} alt='девушки фото'/>
                </div>
                <div className='card wm-10'>
                    <img  src={womenPh4} alt='девушки фото'/>
                </div>
                <div className='card wm-11'>
                    <img  src={womenPh42} alt='девушки фото'/>
                </div>
                <div className='card wm-12'>
                    <img  src={womenPh8} alt='девушки фото'/>
                </div>
                <div className='card wm-13'>
                    <img  src={womenPh9} alt='девушки фото'/>
                </div>
                <div className='card wm-14'>
                    <img  src={womenPh10} alt='девушки фото'/>
                </div>
                <div className='card wm-15'>
                    <img  src={womenPh12} alt='девушки фото'/>
                </div>
                <div className='card wm-16'>
                    <img  src={womenPh7} alt='девушки фото'/>
                </div>
                <div className='card wm-17'>
                    <img  src={womenPh11} alt='девушки фото'/>
                </div>
                <div className='card wm-18'>
                    <img  src={womenPh38} alt='девушки фото'/>
                </div>
                <div className='card wm-19'>
                    <img  src={womenPh39} alt='девушки фото'/>
                </div>
                <div className='card wm-20'>
                    <img  src={womenPh40} alt='девушки фото'/>
                </div>
                <div className='card wm-21'>
                    <img  src={womenPh13} alt='девушки фото'/>
                </div>
                <div className='card wm-22'>
                    <img  src={womenPh14} alt='девушки фото'/>
                </div>
                <div className='card wm-23'>
                    <img  src={womenPh15} alt='девушки фото'/>
                </div>
                <div className='card wm-24'>
                    <img  src={womenPh16} alt='девушки фото'/>
                </div>
                <div className='card wm-25'>
                    <img  src={womenPh17} alt='девушки фото'/>
                </div>
                <div className='card wm-26'>
                    <img  src={womenPh22} alt='девушки фото'/>
                </div>
                <div className='card wm-27'>
                    <img  src={womenPh24} alt='девушки фото'/>
                </div>
                <div className='card wm-28'>
                    <img  src={womenPh55} alt='девушки фото'/>
                </div>
                <div className='card wm-29'>
                    <img  src={womenPh27} alt='девушки фото'/>
                </div>
                <div className='card wm-30'>
                    <img  src={womenPh23} alt='девушки фото'/>
                </div>
                <div className='card wm-31'>
                    <img  src={womenPh25} alt='девушки фото'/>
                </div>
                <div className='card wm-32'>
                    <img  src={womenPh26} alt='девушки фото'/>
                </div>
                <div className='card wm-33'>
                    <img  src={womenPh29} alt='девушки фото'/>
                </div>
                <div className='card wm-34'>
                    <img  src={womenPh32} alt='девушки фото'/>
                </div>
                <div className='card wm-35'>
                    <img  src={womenPh33} alt='девушки фото'/>
                </div>
                <div className='card wm-36'>
                    <img  src={womenPh30} alt='девушки фото'/>
                </div>
                <div className='card wm-37'>
                    <img  src={womenPh34} alt='девушки фото'/>
                </div>
                <div className='card wm-38'>
                    <img  src={womenPh35} alt='девушки фото'/>
                </div>
                <div className='card wm-39'>
                    <img  src={womenPh51} alt='девушки фото'/>
                </div>
                <div className='card wm-40'>
                    <img  src={womenPh19} alt='девушки фото'/>
                </div>
                <div className='card wm-41'>
                    <img  src={womenPh52} alt='девушки фото'/>
                </div>
                <div className='card wm-42'>
                    <img  src={womenPh44} alt='девушки фото'/>
                </div>
                <div className='card wm-43'>
                    <img  src={womenPh50} alt='девушки фото'/>
                </div>
                <div className='card wm-44'>
                    <img  src={womenPh18} alt='девушки фото'/>
                </div>
                <div className='card wm-45'>
                    <img  src={womenPh46} alt='девушки фото'/>
                </div>
                <div className='card wm-46'>
                    <img  src={womenPh20} alt='девушки фото'/>
                </div>
                <div className='card wm-47'>
                    <img  src={womenPh21} alt='девушки фото'/>
                </div>
                <div className='card wm-48'>
                    <img  src={womenPh54} alt='девушки фото'/>
                </div>
                <div className='card wm-49'>
                    <img  src={womenPh57} alt='девушки фото'/>
                </div>
                <div className='card wm-50'>
                    <img  src={womenPh2} alt='девушки фото'/>
                </div>
                <div className='card wm-51'>
                    <img  src={womenPh53} alt='девушки фото'/>
                </div>
                <div className='card wm-52'>
                    <img  src={womenPh37} alt='девушки фото'/>
                </div>
        </div>
   
    ) 

}

export default Women;