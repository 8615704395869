
import React, { useLayoutEffect } from 'react';
import gsap from "gsap";
import { useForm, ValidationError } from '@formspree/react';
import './App.css';
import iconVK from './icons/ivk.png';
import iconWApp from './icons/iwhatsapp.png';
import { Link } from 'react-router-dom';

function Contact() {

  useLayoutEffect(() => {
    gsap.to(".header", {opacity:1, duration:3});
    gsap.to("form", {opacity:1, duration:4, delay:1});

},[])

    const [state, handleSubmit] = useForm("xgegknka");
    if (state.succeeded) {
        return (
        <div className='alert' >
          <p className='alert_par'>Ваша заявка принята! </p>
          <p className='alert_par'>Я отвечу Вам в течение 24 часов. Благодарю!</p>
        </div>
        )
    }
    return(
    <div>

      <div className='container_contact'>
        <header className="head">
            <h1 className="header">КОНТАКТЫ</h1>
        </header>
        <main className='cont'>            
              <div className='contact_head'>
                  <p >Просмотрите мои работы, сформируйте стиль и выражение, которые вы хотите увидеть на своих  фотографиях, расскажите мне о своих предпочтениях и пожеланиях!</p>
                  <Link to= "tel:+79283996411" className='tel'>+79283996411</Link>
                  <p>г. Ставрополь</p>
              </div>
              <div className='contact_par'>
                  <p className='cont_par'>Буду рада стать Вашим фотографом и создать  вместе маленькую историю!</p>
                <form onSubmit={handleSubmit}>
                    <label className='input_par' htmlFor="email">
                      Ваш Email
                    </label>
                    <input className='text_input' required
                      id="email"
                      type="email" 
                      name="email"
                    />
                    <ValidationError 
                      prefix="Email" 
                      field="email"
                      errors={state.errors}
                    />
        
                    <label className='input_par' htmlFor="text">
                      Опишите планируемое событие
                    </label>
                    <input className='text_input' required
                      id="text"
                      type="text" 
                      name="text"
                    />
                    <ValidationError 
                      prefix="Text" 
                      field="text"
                      errors={state.errors}
                    />
        
                    <button className='input_btn' type="submit" disabled={state.submitting}>
                        ОТПРАВИТЬ ЗАЯВКУ
                    </button>
                </form>
              </div>
        </main>     
      </div>

      <footer className='social'>
            <Link to = "https://vk.com/id17296526"><img className='social__icons' src={iconVK} alt='VK'/></Link>
            <Link to = "https://wa.me/+79283996411" ><img className='social__icons' src={iconWApp} alt='WApp'/></Link>
      </footer>
    </div>
    )
}
export default Contact;