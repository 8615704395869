import './App.css';

import pregPh1 from "./pregnancy/1.jpg";
import pregPh2 from "./pregnancy/2.jpg";
import pregPh3 from "./pregnancy/3.jpg";
import pregPh4 from "./pregnancy/4.jpg";
import pregPh5 from "./pregnancy/5.jpg";
import pregPh6 from "./pregnancy/6.jpg";
import pregPh7 from "./pregnancy/7.jpg";
import pregPh8 from "./pregnancy/8.jpg";
import pregPh9 from "./pregnancy/9.jpg";
import pregPh10 from "./pregnancy/10.jpg";
import pregPh11 from "./pregnancy/11.jpg";
import pregPh25 from "./pregnancy/25.jpg";
import pregPh14 from "./pregnancy/14.jpg";
import pregPh24 from "./pregnancy/24.jpg";
import pregPh15 from "./pregnancy/15.jpg";
import pregPh17 from "./pregnancy/17.jpg";
import pregPh23 from "./pregnancy/23.jpg";
import pregPh18 from "./pregnancy/18.jpg";
import pregPh20 from "./pregnancy/20.jpg";
import pregPh21 from "./pregnancy/21.jpg";
import pregPh12 from "./pregnancy/12.jpg";
import pregPh19 from "./pregnancy/19.jpg";
import pregPh13 from "./pregnancy/13.jpg";


function Pregnancy() {
    return (
    
            <div className='cards-preg-grid'>
                <div className='card p-1'>
                    <img  src={pregPh1} alt='беременные фото'/>
                </div>
                <div className='card p-2'>
                    <img  src={pregPh2} alt='беременные фото'/>
                </div>         
                <div className='card p-3'>
                    <img src={pregPh3} alt='беременные фото'/>
                </div>
                <div className='card p-4'>
                    <img  src={pregPh4} alt='беременные фото'/>
                </div>
                <div className='card p-5'>
                    <img  src={pregPh5} alt='беременные фото'/>
                </div>         
                <div className='card p-6'>
                    <img  src={pregPh6} alt='беременные фото'/>
                </div>
                <div className='card p-7'>
                    <img  src={pregPh7} alt='беременные фото'/>
                </div>
                <div className='card p-8'>
                    <img  src={pregPh8} alt='беременные фото'/>
                </div>         
                <div className='card p-9'>
                    <img  src={pregPh9} alt='беременные фото'/>
                </div>
                <div className='card p-10'>
                    <img  src={pregPh10} alt='беременные фото'/>
                </div>
                <div className='card p-11'>
                    <img src={pregPh11} alt='беременные фото'/>
                </div>         
                <div className='card p-12'>
                    <img  src={pregPh25} alt='беременные фото'/>
                </div>
                <div className='card p-13'>
                    <img  src={pregPh14} alt='беременные фото'/>
                </div>
                <div className='card p-14'>
                    <img  src={pregPh24} alt='беременные фото'/>
                </div>
                <div className='card p-15'>
                    <img  src={pregPh15} alt='беременные фото'/>
                </div>         
                <div className='card p-16'>
                <img  src={pregPh17} alt='беременные фото'/>
                </div>
                <div className='card p-17'>
                    <img src={pregPh23} alt='беременные фото'/>
                </div>
                <div className='card p-18'>
                    <img  src={pregPh18} alt='беременные фото'/>
                </div>
                <div className='card p-19'>
                    <img src={pregPh21} alt='беременные фото'/>
                </div>
                <div className='card p-20'>
                    <img  src={pregPh20} alt='беременные фото'/>
                </div>
                <div className='card p-21'>
                    <img  src={pregPh12} alt='беременные фото'/>
                </div>
                <div className='card p-22'>
                    <img  src={pregPh19} alt='беременные фото'/>
                </div>
                <div className='card p-23'>
                    <img  src={pregPh13} alt='беременные фото'/>
                </div>
        </div>
   
    ) 

}

export default Pregnancy;