export const data = [
    {
        photo: '../homePhoto/1.jpg'
    },
    {
        photo: '../homePhoto/2.jpg'
    },
    {
        photo: '../homePhoto/3.jpg'
    },
    {
        photo: '../homePhoto/4.jpg'
    },
    {
        photo: '../homePhoto/5.jpg'
    },
  
];



export const dataDescribe = [
    {
        picture: "https://images.unsplash.com/photo-1550784718-990c6de52adf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fHdlZGRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        describtion: "СВАДЬБА - это день, который вы запопните навсегда.  Быть тем, кто увековечит знаменательный день жениха и невесты, — большая честь. И большая ответственность. В этот день много всего происходит, я буду с вами в каждый момент, оставаясь при этом как можно менее заметной. Вы можете сосредоточиться только на том, чтобы в полной мере насладиться своим днем ​​с семьей и друзьями!  СТОИМОСТЬ: полный день - 10 часов -  от 20 000 рублей; полдня - 5 часов - от 12 000 рублей.",
        showMore:false,
    },
   
    {
        picture: "https://images.unsplash.com/photo-1623083593166-c9fe77371021?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHBvcnRyYWl0JTIwYmxhY2slMjBhbmQlMjB3aGl0ZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        describtion: "ПОРТРЕТ - это полное доверие между человеком в кадре и фотографом. Вы раскрываетесь у меня в кадре, с новой для вас стороны, а я в свою очередь открываюсь вам, вдохновляюсь. Это может быть, как новый для вас образ, непривычный, странный, так и новые эмоции, которые вы можете испытать от этой съёмки. Влюбиться в себя. Особенно интересно снимать детей, их эмоции на поверхности, нужно только поймать момент!  СТОИМОСТЬ: от 8 000 рублей.",
        showMore:false,
    },
   
    {
        picture: "https://images.unsplash.com/photo-1506836467174-27f1042aa48c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGZhbWlseXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        describtion: "СЕМЕЙНАЯ ФОТОСЕССИЯ - это не просто фоторгафия, это возможность собрать вместе всю семью, и провести время весело и непринужденно. Фотографировать семью весело и сложно, у каждого свой характер, настроение и предпочтения, но для меня это лучший вызов. Я стараюсь заполнить это время игрой и смехом, особенно для детей... СТОИМОСТЬ: 2-4 часа - от 10 000 рублей;  5-7 часов - щт 15 000 рублей.",
        showMore:false,
    },
    {
        picture: "https://images.unsplash.com/photo-1561524891-dcb77670fe3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjIzfHxmYW1pbHl8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        describtion: "СОБЫТИЯ нашей жизни очень разнообразны, но есть те, которые хочется оставить в памяти навсегда, это например история любви, ожидание малыша и рождение ребенка. Истории любви я определенно люблю фотографировать больше всего, столько положительных и сильных эмоций, для влюбленных время как будто останавливается и они видят только друг друга. Ожидание и рождение ребенка - это очень трогательная, бережная, мягкая съемка. Я стараюсь уделить максимальное внимание комфорту мамы и малыша. СТОИМОСТЬ: Новорожденные - от 10 000 рублей; История любви - от 5 000 рублей, Беременность - от 5 000 рублей.",
        showMore:false,
    },
    {
        picture: "https://images.unsplash.com/photo-1503315082045-a2bfb5e7f56e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjB8fGJpcnRoZGF5JTIwcGFydHl8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        describtion: "ВАШ ПРАЗДНИК будет ярким и незабываемым! Я проживу этот день с вами и постараюсь запечатлеть самые трогательные, забавные, веселые моменты. Поймать ваше настроение, эмоции, чувства. Особенно я люблю снимать детские праздники. Неподдельные детские эмоции, восторг, радость - ни с чем не сравнимы! Буду рада сделать для Вас прекрасные фото - открытки к Новому году в студии и на природе. СТОИМОСТЬ: 2-4 часа - от 10 000 рублей; 5-7 часов - щт 15 000 рублей.",
        showMore:false,
    }

]