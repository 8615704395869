import './App.css';

import christPh3 from "./christening/3.jpg";
import christPh8 from "./christening/8.jpg";
import christPh5 from "./christening/5.jpg";
import christPh4 from "./christening/4.jpg";
import christPh7 from "./christening/7.jpg";
import christPh6 from "./christening/6.jpg";
import christPh9 from "./christening/9.jpg";
import christPh14 from "./christening/14.jpg";
import christPh15 from "./christening/15.jpg";
import christPh12 from "./christening/12.jpg";
import christPh11 from "./christening/11.jpg";
import christPh16 from "./christening/16.jpg";
import christPh17 from "./christening/17.jpg";
import christPh19 from "./christening/19.jpg";
import christPh23 from "./christening/23.jpg";
import christPh20 from "./christening/20.jpg";
import christPh10 from "./christening/10.jpg";
import christPh18 from "./christening/18.jpg";
import christPh22 from "./christening/22.jpg";

function Christ () {
    return (
        <div>
            <div className='cards-christ-grid'>
                <div className='card c-1'>
                    <img  src={christPh3} alt='крещение фото'/>
                </div>
                <div className='card c-2'>
                    <img  src={christPh8} alt='крещение фото'/>
                </div>
                <div className='card c-3'>
                    <img src={christPh5} alt='крещение фото'/>
                </div>
                <div className='card c-4'>
                    <img  src={christPh4} alt='крещение фото'/>
                </div>
                <div className='card c-5'>
                    <img  src={christPh7} alt='крещение фото'/>
                </div>
                <div className='card c-6'>
                    <img  src={christPh6} alt='крещение фото'/>
                </div>
                <div className='card c-7'>
                    <img  src={christPh9} alt='крещение фото'/>
                </div>
                <div className='card c-8'>
                    <img src={christPh14} alt='крещение фото'/>
                </div>
                <div className='card c-9'>
                    <img  src={christPh15} alt='крещение фото'/>
                </div>
                <div className='card c-10'>
                    <img  src={christPh12} alt='крещение фото'/>
                </div>
                <div className='card c-11'>
                    <img src={christPh11} alt='крещение фото'/>
                </div>
                <div className='card c-12'>
                    <img  src={christPh16} alt='крещение фото'/>
                </div>
                <div className='card c-13'>
                    <img  src={christPh17} alt='крещение фото'/>
                </div>
                <div className='card c-14'>
                    <img src={christPh19} alt='крещение фото'/>
                </div>
                <div className='card c-15'>
                    <img src={christPh23} alt='крещение фото'/>
                </div>
                <div className='card c-16'>
                    <img  src={christPh20} alt='крещение фото'/>
                </div>
                <div className='card c-17'>
                    <img  src={christPh10} alt='крещение фото'/>
                </div>
                <div className='card c-18'>
                    <img  src={christPh18} alt='крещение фото'/>
                </div>
                <div className='card c-19'>
                    <img  src={christPh22} alt='крещение фото'/>
                </div>              
            </div>
        </div>
    )     
}

export default Christ;