import { useState, useEffect, useLayoutEffect } from 'react'
import { data } from './data'
import gsap from "gsap";
import './App.css'

import arrowOne from './icons/arrow-left.png'
import arrowTwo from './icons/arrow-right.png'
import iconCopy from './icons/copyright.png'
import LoaderPage from './LoaderPage'


function Home() {
      const [picture, setPicture] = useState(0);
      const {photo} = data[picture]
    

      const previousPicture = () => {
         setPicture ((picture => {
            picture --;
            if (picture < 0) {
                  return data.length -1;
            }
            return picture
         }))
      }

      const nextPicture = () => {
         setPicture ((picture => {
         picture++;
         if (picture > data.length-1) {
         picture=0
         }
         return picture
         }))
      }

      const [stateLoader, setStateLoader] = useState(true);

      useEffect(() => {
       const timer = setTimeout(() => setStateLoader(false), 3000);
         return() => clearTimeout(timer)
     
      }, [])
    
    useLayoutEffect(() => {
      gsap.to(".header", {opacity:1, duration:3});
      gsap.to(".par_one", {opacity:1, duration:3, delay:1});
      gsap.to(".par", {opacity:1, duration:3, delay:2})
    },[])

      return(
      <div>
            {stateLoader && <LoaderPage/>}
         <div className='content'>
            <header className='head'>
               <h1 className='header'>ФОТОГРАФИРУЮ ДУШУ ЧЕРЕЗ ТЕЛО...</h1>
            </header>   
            <main className='photo'>    
               <div>
                  <img className='photografer' src={photo} alt="семейные фотографии"/>
               </div>
               
                  <button className='btn btn-left' onClick={previousPicture}><img className='left' src={arrowOne} alt="left"/></button>
                  <button className='btn btn-right' onClick={nextPicture}><img className='right' src={arrowTwo} alt="right"/></button>
               
               <div className='head_par'>
                  <p className='par_one'>Проявление души...</p>
                  <p className='par'>В  эмоциях, взгляде, улыбках, объятиях, смехе, в искорках счастья в глазах.</p>
               </div>  
            </main>
         </div>
            <footer className='footer'>
               <p className='icon-par'><img className='icon' src={iconCopy} alt='icon'/> Елена Ковешникова, 2024г. </p>
            </footer>      
      </div>
    )
}
export default Home;