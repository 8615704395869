import './App.css';

import kidsPh1 from "./children/1.jpg";
import kidsPh5 from "./children/5.jpg";
import kidsPh7 from "./children/7.jpg";
import kidsPh3 from "./children/3.jpg";
import kidsPh4 from "./children/4.jpg";
import kidsPh13 from "./children/13.jpg";
import kidsPh14 from "./children/14.jpg";
import kidsPh9 from "./children/9.jpg";
import kidsPh11 from "./children/11.jpg";
import kidsPh10 from "./children/10.jpg";
import kidsPh17 from "./children/17.jpg";
import kidsPh12 from "./children/12.jpg";
import kidsPh15 from "./children/15.jpg";
import kidsPh16 from "./children/16.jpg";
import kidsPh2 from "./children/2.jpg";
import kidsPh18 from "./children/18.jpg";
import kidsPh21 from "./children/21.jpg";
import kidsPh19 from "./children/19.jpg";
import kidsPh20 from "./children/20.jpg";

function Kids() {
    return (
        <div className='cards-kids-grid'>
            <div className='card k-1'>
                <img  src={kidsPh1} alt='детские фото'/>
            </div>
            <div className='card k-2'>
                <img src={kidsPh5} alt='детские фото'/>
            </div>
            <div className='card k-3'>
                <img src={kidsPh7} alt='детские фото'/>
            </div>
            <div className='card k-4'> 
                <img src={kidsPh3} alt='детские фото'/>
            </div>
            <div className='card k-5'>
                <img src={kidsPh4} alt='детские фото'/>
            </div>
            <div className='card k-6'>
                <img src={kidsPh13} alt='детские фото'/>
            </div>
            <div className='card k-7'>
                <img  src={kidsPh14} alt='детские фото'/>
            </div>
            <div className='card k-8'>
                <img  src={kidsPh9} alt='детские фото'/>
            </div>
            <div className='card k-9'>
                <img src={kidsPh11} alt='детские фото'/>
            </div>           
            <div className='card k-10'>
                <img src={kidsPh10} alt='детские фото'/>
            </div>
            <div className='card k-11'>
                <img src={kidsPh17} alt='детские фото'/>
            </div>
            <div className='card k-12'>
                <img src={kidsPh12} alt='детские фото'/>
            </div>
            <div className='card k-13'>
                <img  src={kidsPh15} alt='детские фото'/>            
            </div>
            <div className='card k-14'>
                <img src={kidsPh16} alt='детские фото'/>
            </div>
            <div className='card k-15'>
                <img src={kidsPh2} alt='детские фото'/>
            </div>
            <div className='card k-16'>
                <img src={kidsPh18} alt='детские фото'/>
            </div>
            <div className='card k-17'>
                <img src={kidsPh21} alt='детские фото'/>
            </div>
            <div className='card k-18'>              
                <img  src={kidsPh19} alt='детские фото'/>
            </div>
            <div className='card k-19'>
                <img src={kidsPh20} alt='детские фото'/>
            </div>
       
        </div>
        
    ) 

    
}

export default Kids;