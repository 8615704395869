import './App.css';



import weddingPh14 from "./wedding/14.jpg";
import weddingPh23 from "./wedding/23.jpg";
import weddingPh6 from "./wedding/6.jpg";
import weddingPh8 from "./wedding/8.jpg";
import weddingPh2 from "./wedding/2.jpg";
import weddingPh3 from "./wedding/3.jpg";
import weddingPh15 from "./wedding/15.jpg";
import weddingPh10 from "./wedding/10.jpg";
import weddingPh11 from "./wedding/11.jpg";
import weddingPh7 from "./wedding/7.jpg";
import weddingPh18 from "./wedding/18.jpg";
import weddingPh13 from "./wedding/13.jpg";
import weddingPh9 from "./wedding/9.jpg";
import weddingPh16 from "./wedding/16.jpg";
import weddingPh17 from "./wedding/17.jpg";
import weddingPh21 from "./wedding/21.jpg";
import weddingPh19 from "./wedding/19.jpg";
import weddingPh20 from "./wedding/20.jpg";
import weddingPh22 from "./wedding/22.jpg";
import weddingPh26 from "./wedding/26.jpg";
import weddingPh27 from "./wedding/27.jpg";
import weddingPh24 from "./wedding/24.jpg";
import weddingPh28 from "./wedding/28.jpg";
import weddingPh29 from "./wedding/29.jpg";
import weddingPh30 from "./wedding/30.jpg";
import weddingPh31 from "./wedding/31.jpg";
import weddingPh32 from "./wedding/32.jpg";
import weddingPh33 from "./wedding/33.jpg";
import weddingPh34 from "./wedding/34.jpg";
import weddingPh35 from "./wedding/35.jpg";
import weddingPh36 from "./wedding/36.jpg";
import weddingPh1 from "./wedding/1.jpg";
import weddingPh38 from "./wedding/38.jpg";
import weddingPh40 from "./wedding/40.jpg";
import weddingPh41 from "./wedding/41.jpg";
import weddingPh39 from "./wedding/39.jpg";
import weddingPh42 from "./wedding/42.jpg";
import weddingPh43 from "./wedding/43.jpg";
import weddingPh44 from "./wedding/44.jpg";
import weddingPh45 from "./wedding/45.jpg";
import weddingPh46 from "./wedding/46.jpg";


function Wedding() {
    return (    
            <div className='cards-wedding-grid'>
                <div className='card w-1'>
                    <img  src={weddingPh14} alt='свадебные фото'/>
                </div>
                <div className='card w-2'>
                    <img  src={weddingPh23} alt='свадебные фото'/>
                </div>
                <div className='card w-3'>
                    <img  src={weddingPh6} alt='свадебные фото'/>
                </div>
                <div className='card w-4'>
                    <img  src={weddingPh8} alt='свадебные фото'/>
                </div>
                <div className='card w-5'>
                    <img  src={weddingPh2} alt='свадебные фото'/>
                </div>
                <div className='card w-6'>
                    <img  src={weddingPh3} alt='свадебные фото'/>
                </div>
                <div className='card w-7'>
                    <img  src={weddingPh15} alt='свадебные фото'/>
                </div>
                <div className='card w-8'>
                    <img  src={weddingPh10} alt='свадебные фото'/>
                </div>
                <div className='card w-9'>
                    <img  src={weddingPh11} alt='свадебные фото'/>
                </div>
                <div className='card w-10'>
                    <img  src={weddingPh7} alt='свадебные фото'/>
                </div>
                <div className='card w-11'>
                    <img  src={weddingPh18} alt='свадебные фото'/>
                </div>
                <div className='card w-12'>
                    <img  src={weddingPh9} alt='свадебные фото'/>
                </div>
                <div className='card w-13'>
                    <img  src={weddingPh16} alt='свадебные фото'/>
                </div>
                <div className='card w-14'>
                    <img  src={weddingPh17} alt='свадебные фото'/>
                </div>
                <div className='card w-15'>
                    <img  src={weddingPh13} alt='свадебные фото'/>
                </div>
                <div className='card w-16'>
                    <img  src={weddingPh21} alt='свадебные фото'/>
                </div>
                <div className='card w-17'>
                    <img  src={weddingPh19} alt='свадебные фото'/>
                </div>
                <div className='card w-18'>
                    <img  src={weddingPh20} alt='свадебные фото'/>
                </div>
                <div className='card w-19'>
                    <img  src={weddingPh22} alt='свадебные фото'/>
                </div>
                <div className='card w-20'>
                    <img  src={weddingPh26} alt='свадебные фото'/>
                </div>
                <div className='card w-21'>
                    <img  src={weddingPh27} alt='свадебные фото'/>
                </div>
                <div className='card w-22'>
                    <img  src={weddingPh24} alt='свадебные фото'/>
                </div>
                <div className='card w-23'>
                    <img  src={weddingPh28} alt='свадебные фото'/>
                </div>
                <div className='card w-24'>
                    <img  src={weddingPh29} alt='свадебные фото'/>
                </div>
                <div className='card w-25'>
                    <img  src={weddingPh30} alt='свадебные фото'/>
                </div>
                <div className='card w-26'>
                    <img  src={weddingPh31} alt='свадебные фото'/>
                </div>
                <div className='card w-27'>
                    <img  src={weddingPh32} alt='свадебные фото'/>
                </div>
                <div className='card w-28'>
                    <img  src={weddingPh33} alt='свадебные фото'/>
                </div>
                <div className='card w-29'>
                    <img  src={weddingPh34} alt='свадебные фото'/>
                </div>
                <div className='card w-30'>
                    <img  src={weddingPh35} alt='свадебные фото'/>
                </div>
                <div className='card w-31'>
                    <img  src={weddingPh36} alt='свадебные фото'/>
                </div>
                <div className='card w-32'>
                    <img  src={weddingPh1} alt='свадебные фото'/>
                </div>
                <div className='card w-33'>
                    <img  src={weddingPh38} alt='свадебные фото'/>
                </div>
                <div className='card w-34'>
                    <img  src={weddingPh40} alt='свадебные фото'/>
                </div>
                <div className='card w-35'>
                    <img  src={weddingPh41} alt='свадебные фото'/>
                </div>
                <div className='card w-36'>
                    <img  src={weddingPh39} alt='свадебные фото'/>
                </div>
                <div className='card w-37'>
                    <img  src={weddingPh42} alt='свадебные фото'/>
                </div>
                <div className='card w-38'>
                    <img  src={weddingPh43} alt='свадебные фото'/>
                </div>
                <div className='card w-39'>
                    <img  src={weddingPh44} alt='свадебные фото'/>
                </div>
                <div className='card w-40'>
                    <img  src={weddingPh45} alt='свадебные фото'/>
                </div>
                <div className='card w-41'>
                    <img  src={weddingPh46} alt='свадебные фото'/>
                </div>               
        </div>   
    ) 

}

export default Wedding;