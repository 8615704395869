import './App.css';

import NewYearPh6 from "./newyear/6.jpg";
import NewYearPh5 from "./newyear/5.jpg";
import NewYearPh8 from "./newyear/8.jpg";
import NewYearPh4 from "./newyear/4.jpg";
import NewYearPh7 from "./newyear/7.jpg";
import NewYearPh10 from "./newyear/10.jpg";
import NewYearPh9 from "./newyear/9.jpg";
import NewYearPh1 from "./newyear/1.jpg";
import NewYearPh2 from "./newyear/2.jpg";
import NewYearPh3 from "./newyear/3.jpg";
import NewYearPh17 from "./newyear/17.jpg";
import NewYearPh18 from "./newyear/18.jpg";
import NewYearPh11 from "./newyear/11.jpg";
import NewYearPh12 from "./newyear/12.jpg";
import NewYearPh15 from "./newyear/15.jpg";
import NewYearPh13 from "./newyear/13.jpg";
import NewYearPh14 from "./newyear/14.jpg";
import NewYearPh20 from "./newyear/20.jpg";
import NewYearPh19 from "./newyear/19.jpg";
import NewYearPh21 from "./newyear/21.jpg";
import NewYearPh22 from "./newyear/22.jpg";
import NewYearPh23 from "./newyear/23.jpg";
import NewYearPh32 from "./newyear/32.jpg";
import NewYearPh35 from "./newyear/35.jpg";
import NewYearPh34 from "./newyear/34.jpg";
import NewYearPh37 from "./newyear/37.jpg";
import NewYearPh30 from "./newyear/30.jpg";
import NewYearPh31 from "./newyear/31.jpg";
import NewYearPh55 from "./newyear/55.jpg";
import NewYearPh33 from "./newyear/33.jpg";
import NewYearPh36 from "./newyear/36.jpg";
import NewYearPh56 from "./newyear/56.jpg";
import NewYearPh57 from "./newyear/57.jpg";
import NewYearPh53 from "./newyear/53.jpg";
import NewYearPh40 from "./newyear/40.jpg";
import NewYearPh41 from "./newyear/41.jpg";
import NewYearPh38 from "./newyear/38.jpg";
import NewYearPh39 from "./newyear/39.jpg";
import NewYearPh24 from "./newyear/24.jpg";
import NewYearPh25 from "./newyear/25.jpg";
import NewYearPh26 from "./newyear/26.jpg";
import NewYearPh29 from "./newyear/29.jpg";
import NewYearPh27 from "./newyear/27.jpg";
import NewYearPh43 from "./newyear/43.jpg";
import NewYearPh51 from "./newyear/51.jpg";
import NewYearPh46 from "./newyear/46.jpg";
import NewYearPh47 from "./newyear/47.jpg";
import NewYearPh49 from "./newyear/49.jpg";
import NewYearPh42 from "./newyear/42.jpg";
import NewYearPh50 from "./newyear/50.jpg";
import NewYearPh44 from "./newyear/44.jpg";
import NewYearPh52 from "./newyear/52.jpg";
import NewYearPh48 from "./newyear/48.jpg";



function NewYear() {
    return (    
            <div className='cards-ny-grid'>
                <div className='card ny-1'>
                    <img  src={NewYearPh6} alt='новый год фото'/>
                </div>                
                <div className='card ny-2'>
                    <img src={NewYearPh5} alt='новый год фото'/>
                </div>  
                <div className='card ny-3'>
                    <img src={NewYearPh8} alt='новый год фото'/>
                </div>  
                <div className='card ny-4'>
                    <img  src={NewYearPh4} alt='новый год фото'/>
                </div>  
                <div className='card ny-5'>
                    <img src={NewYearPh7} alt='новый год фото'/>
                </div>  
                <div className='card ny-6'>
                    <img  src={NewYearPh10} alt='новый год фото'/>
                </div>  
                <div className='card ny-7'>
                    <img  src={NewYearPh9} alt='новый год фото'/>
                </div>  
                <div className='card ny-8'>
                    <img  src={NewYearPh1} alt='новый год фото'/>
                </div>  
                <div className='card ny-9'>
                    <img  src={NewYearPh2} alt='новый год фото'/>
                </div>  
                <div className='card ny-10'>
                    <img  src={NewYearPh3} alt='новый год фото'/>
                </div>  
                <div className='card ny-11'>
                    <img src={NewYearPh17} alt='новый год фото'/>
                </div>  
                <div className='card ny-12'>
                    <img  src={NewYearPh18} alt='новый год фото'/>
                </div> 
                <div className='card ny-13'>
                    <img  src={NewYearPh11} alt='новый год фото'/>
                </div>  
                <div className='card ny-14'>
                    <img  src={NewYearPh12} alt='новый год фото'/>
                </div>  
                <div className='card ny-15'>
                    <img  src={NewYearPh15} alt='новый год фото'/>
                </div> 
                <div className='card ny-16'>
                    <img  src={NewYearPh13} alt='новый год фото'/>
                </div>  
                <div className='card ny-17'>
                    <img src={NewYearPh14} alt='новый год фото'/>
                </div> 
                <div className='card ny-18'>
                    <img src={NewYearPh20} alt='новый год фото'/>
                </div> 
                <div className='card ny-19'>
                    <img  src={NewYearPh19} alt='новый год фото'/>
                </div>  
                <div className='card ny-20'>
                    <img  src={NewYearPh21} alt='новый год фото'/>
                </div>  
                <div className='card ny-21'>
                    <img  src={NewYearPh22} alt='новый год фото'/>
                </div>  
                <div className='card ny-22'>
                    <img src={NewYearPh23} alt='новый год фото'/>
                </div> 
                <div className='card ny-23'>
                    <img src={NewYearPh32} alt='новый год фото'/>
                </div> 
                <div className='card ny-24'>
                    <img  src={NewYearPh37} alt='новый год фото'/>
                </div>  
                <div className='card ny-25'>
                    <img  src={NewYearPh34} alt='новый год фото'/>
                </div> 
                <div className='card ny-26'>
                    <img  src={NewYearPh35} alt='новый год фото'/>
                </div> 
                <div className='card ny-27'>
                    <img  src={NewYearPh30} alt='новый год фото'/>
                </div>   
                <div className='card ny-28'>
                    <img  src={NewYearPh31} alt='новый год фото'/>
                </div>  
                <div className='card ny-29'>
                    <img src={NewYearPh55} alt='новый год фото'/>
                </div>  
                <div className='card ny-30'>
                    <img  src={NewYearPh33} alt='новый год фото'/>
                </div>  
                <div className='card ny-31'>
                    <img  src={NewYearPh36} alt='новый год фото'/>
                </div>  
                <div className='card ny-32'>
                    <img  src={NewYearPh56} alt='новый год фото'/>
                </div>  
                <div className='card ny-33'>
                    <img  src={NewYearPh57} alt='новый год фото'/>
                </div> 
                <div className='card ny-34'>
                    <img  src={NewYearPh53} alt='новый год фото'/>
                </div> 
                <div className='card ny-35'>
                    <img src={NewYearPh40} alt='новый год фото'/>
                </div>  
                <div className='card ny-36'>
                    <img src={NewYearPh41} alt='новый год фото'/>
                </div>  
                <div className='card ny-37'>
                    <img  src={NewYearPh38} alt='новый год фото'/>
                </div>  
                <div className='card ny-38'>
                    <img  src={NewYearPh39} alt='новый год фото'/>
                </div> 
                <div className='card ny-39'>
                    <img  src={NewYearPh24} alt='новый год фото'/>
                </div> 
                <div className='card ny-40'>
                    <img  src={NewYearPh25} alt='новый год фото'/>
                </div> 
                <div className='card ny-41'>
                    <img  src={NewYearPh26} alt='новый год фото'/>
                </div> 
                <div className='card ny-42'>
                    <img  src={NewYearPh29} alt='новый год фото'/>
                </div>  
                <div className='card ny-43'>
                    <img src={NewYearPh27} alt='новый год фото'/>
                </div>  

                <div className='card ny-44'>
                    <img src={NewYearPh43} alt='новый год фото'/>
                </div>  
                <div className='card ny-45'>
                    <img  src={NewYearPh51} alt='новый год фото'/>
                </div> 
                <div className='card ny-46'>
                    <img  src={NewYearPh46} alt='новый год фото'/>
                </div> 
                <div className='card ny-47'>
                    <img  src={NewYearPh47} alt='новый год фото'/>
                </div>  
                <div className='card ny-48'>
                    <img  src={NewYearPh49} alt='новый год фото'/>
                </div> 
                <div className='card ny-49'>
                    <img  src={NewYearPh44} alt='новый год фото'/>
                </div> 
                <div className='card ny-50'>
                    <img  src={NewYearPh42} alt='новый год фото'/>
                </div> 
                <div className='card ny-51'>
                    <img  src={NewYearPh50} alt='новый год фото'/>
                </div>  
                <div className='card ny-52'>
                    <img  src={NewYearPh52} alt='новый год фото'/>
                </div> 
                <div className='card ny-53'>
                    <img  src={NewYearPh48} alt='новый год фото'/>
                </div>  
        </div>
   
    ) 

}

export default NewYear;