import { useLayoutEffect } from 'react';
import gsap from "gsap";
import './App.css'
import myPhoto from './img/lena.jpg'

function About() {
    useLayoutEffect(() => {
        gsap.to(".header", {opacity:1, duration:3,});
        gsap.to(".image_container", {opacity:1, ease:"power3.in", duration:2, delay:1});
        gsap.to(".about__title", {opacity:1, ease:"power3.in", duration:2, delay:1.5});
        gsap.to(".par_about", {opacity:1, ease:"power3.in", duration:2, delay:2});
    },[])

    return(
    <div className='container'>
        <header className="head">
            <h1 className="header">ОБО МНЕ</h1>
        </header>
        <main className="container_about">
            <div className='image_container'>
                <img className='image_about' src={myPhoto} alt='Елена Ковешникова'/>
                <p className='image_par'>Елена Ковешникова</p>  
            </div>
            <div className='allpars'>
                <p className='par_about about__title'>Я, Елена Ковешникова, детский и семейный фотограф. </p>
                <p className='par_about'> В каждую проведенную фотосессию я вкладываю частичку себя, свою душу. Я умею видеть не только правильные ракурсы и красивый свет, но что главнее, я умею видеть мир вашими глазами. Со мной вы получите качественные фотографии с искренними эмоциями, а сама фотосессия пройдет в непринужденной атмосфере. Помогу вам сохранить неповторимые моменты вашей жизни, чтобы они наполняли вас положительными эмоциями  каждый раз, когда вы будете пересматривать эти фото.</p>                
            </div>
        </main>
   
    </div>
    )
}
export default About;