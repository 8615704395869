import './App.css';

import familyPh1 from "./family/2.jpg";
import familyPh2 from "./family/3.jpg";
import familyPh6 from "./family/6.jpg";
import familyPh4 from "./family/5.jpg";
import familyPh13 from "./family/13.jpg";
import familyPh9 from "./family/9.jpg";
import familyPh11 from "./family/11.jpg";
import familyPh12 from "./family/12.jpg";
import familyPh7 from "./family/7.jpg";
import familyPh8 from "./family/8.jpg";
import familyPh10 from "./family/10.jpg";
import familyPh14 from "./family/14.jpg";
import familyPh3 from "./myImages/1.jpg";



function Family() {
    return (
        
        <div className='cards-family-grid'>
            <div className='card f-1'>
                <img  src={familyPh1} alt='семейные фото'/>
            </div>
            <div className='card f-2'>
            <img  src={familyPh13} alt='семейные фото' />
            </div>
            <div className='card f-3'>
                <img src={familyPh4} alt='семейные фото' />
            </div>
            <div className='card f-4'> 
                <img src={familyPh2} alt='семейные фото'/>
            </div>
            <div className='card f-5'>
                <img src={familyPh6} alt='семейные фото'/>
            </div>
            <div className='card f-6'>
                <img src={familyPh9} alt='семейные фото'/>
            </div>
            <div className='card f-7'>
                <img src={familyPh11} alt='семейные фото'/>
            </div>
            <div className='card f-8'>
                <img  src={familyPh12} alt='семейные фото'/>
            </div>
            <div className='card f-9'>
                <img src={familyPh7} alt='семейные фото'/>
            </div>

            <div className='card f-10'>
            <   img  src={familyPh8} alt='семейные фото'/>
            </div>
            <div className='card f-11'>
                <img  src={familyPh10} alt='семейные фото'/>
            </div>
            <div className='card f-12'>
                <img src={familyPh14} alt='семейные фото'/>
            </div>
            <div className='card f-13'>
                <img src={familyPh3} alt='семейные фото'/>
            </div>
        </div>
    
    ) 

    
}

export default Family;