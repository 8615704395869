import './App.css';

import winterPh1 from "./winter/1.jpg";
import winterPh4 from "./winter/4.jpg";
import winterPh5 from "./winter/5.jpg";
import winterPh3 from "./winter/3.jpg";
import winterPh11 from "./winter/11.jpg";
import winterPh18 from "./winter/18.jpg";
import winterPh19 from "./winter/19.jpg";
import winterPh16 from "./winter/16.jpg";
import winterPh17 from "./winter/17.jpg";
import winterPh12 from "./winter/12.jpg";
import winterPh6 from "./winter/6.jpg";
import winterPh7 from "./winter/7.jpg";
import winterPh8 from "./winter/8.jpg";
import winterPh10 from "./winter/10.jpg";
import winterPh15 from "./winter/15.jpg";
import winterPh9 from "./winter/9.jpg";
import winterPh13 from "./winter/13.jpg";
import winterPh14 from "./winter/14.jpg";
import winterPh20 from "./winter/20.jpg";




function Winter() {
    return (    
            <div className='cards-winter-grid'>
                <div className='card wt-1'>
                    <img  src={winterPh1} alt='зима фото'/>
                </div>                
                <div className='card wt-2'>
                    <img  src={winterPh4} alt='зима фото'/>
                </div>   
                <div className='card wt-3'>
                    <img  src={winterPh5} alt='зима фото'/>
                </div>
                <div className='card wt-4'>
                    <img  src={winterPh3} alt='зима фото'/>
                </div>  
                <div className='card wt-5'>
                    <img  src={winterPh11} alt='зима фото'/>
                </div>  
                <div className='card wt-6'>
                    <img  src={winterPh18} alt='зима фото'/>
                </div>    
                <div className='card wt-7'>
                    <img  src={winterPh19} alt='зима фото'/>
                </div>    
                <div className='card wt-8'>
                    <img  src={winterPh20} alt='зима фото'/>
                </div>    
                <div className='card wt-9'>
                    <img  src={winterPh17} alt='зима фото'/>
                </div>    
                <div className='card wt-10'>
                    <img  src={winterPh12} alt='зима фото'/>
                </div>  
                <div className='card wt-11'>
                    <img  src={winterPh6} alt='зима фото'/>
                </div>  
                <div className='card wt-12'>
                    <img  src={winterPh7} alt='зима фото'/>
                </div>   
                <div className='card wt-13'>
                    <img  src={winterPh8} alt='зима фото'/>
                </div>   
                <div className='card wt-14'>
                    <img  src={winterPh10} alt='зима фото'/>
                </div>  
                <div className='card wt-15'>
                    <img  src={winterPh15} alt='зима фото'/>
                </div>   
                <div className='card wt-16'>
                    <img  src={winterPh9} alt='зима фото'/>
                </div>   
                <div className='card wt-17'>
                    <img  src={winterPh16} alt='зима фото'/>
                </div>   
                <div className='card wt-18'>
                    <img  src={winterPh14} alt='зима фото'/>
                </div>   
                <div className='card wt-19'>
                    <img  src={winterPh13} alt='зима фото'/>
                </div>                 
        </div>   
    ) 

}

export default Winter;