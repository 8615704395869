import './App.css';

import lovePh1 from "./lovestory/1.jpg";
import lovePh2 from "./lovestory/2.jpg";
import lovePh5 from "./lovestory/5.jpg";
import lovePh8 from "./lovestory/8.jpg";
import lovePh3 from "./lovestory/3.jpg";
import lovePh6 from "./lovestory/6.jpg";
import lovePh9 from "./lovestory/9.jpg";
import lovePh10 from "./lovestory/10.jpg";
import lovePh11 from "./lovestory/11.jpg";
import lovePh12 from "./lovestory/12.jpg";
import lovePh13 from "./lovestory/13.jpg";
import lovePh17 from "./lovestory/17.jpg";
import lovePh18 from "./lovestory/18.jpg";
import lovePh19 from "./lovestory/19.jpg";
import lovePh16 from "./lovestory/16.jpg";
import lovePh14 from "./lovestory/14.jpg";
import lovePh15 from "./lovestory/15.jpg";
import lovePh20 from "./lovestory/20.jpg";
import lovePh21 from "./lovestory/21.jpg";
import lovePh37 from "./lovestory/37.jpg";
import lovePh33 from "./lovestory/33.jpg";
import lovePh34 from "./lovestory/34.jpg";
import lovePh35 from "./lovestory/35.jpg";
import lovePh36 from "./lovestory/36.jpg";
import lovePh22 from "./lovestory/22.jpg";
import lovePh23 from "./lovestory/23.jpg";
import lovePh25 from "./lovestory/25.jpg";
import lovePh24 from "./lovestory/24.jpg";
import lovePh26 from "./lovestory/26.jpg";
import lovePh27 from "./lovestory/27.jpg";
import lovePh29 from "./lovestory/29.jpg";
import lovePh31 from "./lovestory/31.jpg";
import lovePh28 from "./lovestory/28.jpg";
import lovePh32 from "./lovestory/32.jpg";
import lovePh7 from "./lovestory/7.jpg";
import lovePh38 from "./lovestory/38.jpg";

function LoveStory() {
    return (    
            <div className='cards-love-grid'>
                <div className='card l-1'>
                    <img src={lovePh1} alt='история любви фото'/>
                </div>                
                <div className='card l-2'>
                    <img src={lovePh5} alt='история любви фото'/>
                </div>
                <div className='card l-3'>
                    <img src={lovePh8} alt='история любви фото'/>
                </div>
                <div className='card l-4'>
                    <img src={lovePh3} alt='история любви фото'/>
                </div>
                <div className='card l-5'>
                    <img src={lovePh6} alt='история любви фото'/>
                </div>
                <div className='card l-6'>
                    <img src={lovePh2} alt='история любви фото'/>
                </div>  
                <div className='card l-7'>
                    <img src={lovePh9} alt='история любви фото'/>
                </div>   
                <div className='card l-8'>
                    <img src={lovePh10} alt='история любви фото'/>
                </div> 
                <div className='card l-9'>
                    <img src={lovePh7} alt='история любви фото'/>
                </div>  
                <div className='card l-10'>
                    <img src={lovePh38} alt='история любви фото'/>
                </div>    
                <div className='card l-11'>
                    <img src={lovePh11} alt='история любви фото'/>
                </div>  
                <div className='card l-12'>
                    <img src={lovePh12} alt='история любви фото'/>
                </div>
                <div className='card l-13'>
                    <img src={lovePh13} alt='история любви фото'/>
                </div>
                <div className='card l-14'>
                    <img src={lovePh17} alt='история любви фото'/>
                </div>
                <div className='card l-15'>
                    <img src={lovePh18} alt='история любви фото'/>
                </div>
                <div className='card l-16'>
                    <img src={lovePh19} alt='история любви фото'/>
                </div> 
                <div className='card l-17'>
                    <img src={lovePh16} alt='история любви фото'/>
                </div> 
                <div className='card l-18'>
                    <img src={lovePh14} alt='история любви фото'/>
                </div>
                <div className='card l-19'>
                    <img src={lovePh15} alt='история любви фото'/>
                </div>
                <div className='card l-20'>
                    <img src={lovePh20} alt='история любви фото'/>
                </div>
                <div className='card l-21'>
                    <img src={lovePh21} alt='история любви фото'/>
                </div>
                <div className='card l-22'>
                    <img src={lovePh37} alt='история любви фото'/>
                </div> 
                <div className='card l-23'>
                    <img  src={lovePh33} alt='история любви фото'/>
                </div>
                <div className='card l-24'>
                    <img src={lovePh34} alt='история любви фото'/>
                </div>
                <div className='card l-25'>
                    <img src={lovePh35} alt='история любви фото'/>
                </div>
                <div className='card l-26'>
                    <img src={lovePh36} alt='история любви фото'/>
                </div>
                <div className='card l-27'>
                    <img src={lovePh24} alt='история любви фото'/>
                </div>  
                <div className='card l-28'>
                    <img src={lovePh22} alt='история любви фото'/>
                </div>  
                <div className='card l-29'>
                    <img src={lovePh23} alt='история любви фото'/>
                </div>  
                <div className='card l-30'>
                    <img src={lovePh25} alt='история любви фото'/>
                </div>
                <div className='card l-31'>
                    <img src={lovePh26} alt='история любви фото'/>
                </div>  
                <div className='card l-32'>
                    <img src={lovePh27} alt='история любви фото'/>
                </div>
                <div className='card l-33'>
                    <img src={lovePh29} alt='история любви фото'/>
                </div>
                <div className='card l-34'>
                    <img src={lovePh31} alt='история любви фото'/>
                </div>
                <div className='card l-35'>
                    <img src={lovePh28} alt='история любви фото'/>
                </div>  
                <div className='card l-36'>
                    <img src={lovePh32} alt='история любви фото'/>
                </div>
              
        </div>
   
    ) 

}

export default LoveStory;