import { useLayoutEffect } from 'react';
import gsap from "gsap";
import { Link } from 'react-router-dom';
import './App.css'
import familyIMG from './myImages/1.jpg';
import childrenIMG from './myImages/8.jpg';
import weddingIMG from './myImages/25.jpg';
import loveIMG from './myImages/3.jpg';
import christeningIMG from './myImages/2.jpg';
import pregnancyIMG from './myImages/22.jpg';
import newbornIMG from './myImages/7.jpg';
import daughterIMG from './myImages/23.jpg';
import newyearIMG from './myImages/54.jpg';
import womenIMG from './myImages/31.jpg';
import birthdayIMG from './myImages/24.jpg';
import winterIMG from './myImages/26.jpg';




function MyPhoto() {

    useLayoutEffect(() => {
        gsap.to(".header", {opacity:1, duration:3});
        gsap.to(".photos", {opacity:1, ease:"power3.in", duration:1, delay:0.5, stagger:1});
      
    },[])
    return(     
        <div className="container">
            <header className="head">
                <h1 className="header">ВАША ИСТОРИЯ МОИМИ ГЛАЗАМИ...</h1>
            </header>
            
            <main className="container_image">
            <Link to="/lovestory" className="photos">  
                    <img className='myphoto' src={loveIMG} alt='история любви фото'/>
                    <p className="linkPhoto">история любви</p>
                </Link>  
                <Link to="/wedding" className="photos">  
                    <img className='myphoto' src={weddingIMG} alt='свадьба фото'/>
                    <p className="linkPhoto">свадьба</p>
                </Link>
                <Link to="/family" className="photos">  
                    <img className='myphoto' src={familyIMG} alt='фото семья'/>
                    <p className="linkPhoto">семья</p>
                </Link>  
                <Link to="/kids"  className="photos" >
                    <img className='myphoto' src={childrenIMG} alt='фото дети'/>
                    <p className="linkPhoto">дети</p>
                </Link> 
                <Link to="/daughter" className="photos" >
                    <img className='myphoto' src={daughterIMG} alt='фото мама и дочь'/>
                    <p className="linkPhoto">мама и дочь</p>
                </Link>  
                <Link to="/women" className="photos" >
                    <img className='myphoto' src={womenIMG} alt='фото девушки'/>
                    <p className="linkPhoto">женская</p>
                </Link>            
                <Link to="/christ" className="photos" >
                    <img className='myphoto' src={christeningIMG} alt='фото крещение'/>
                    <p className="linkPhoto">крестины</p>
                </Link>  
                <Link to="/pregnancy" className="photos" >
                    <img className='myphoto' src={pregnancyIMG} alt='фото беременность'/>
                    <p className="linkPhoto"> в ожидании</p>
                </Link>
                <Link to="/newborn" className="photos" >
                    <img className='myphoto' src={newbornIMG} alt='фото новорожденный'/>
                    <p className="linkPhoto">новорожденный</p>
                </Link>
                <Link to="/winter" className="photos" >
                    <img className='myphoto' src={winterIMG} alt='фото зима'/>
                    <p className="linkPhoto">зима</p>
                </Link>
                <Link to="/newyear" className="photos" >
                    <img className='myphoto' src={newyearIMG} alt='фото новый год'/>
                    <p className="linkPhoto">новый год</p>
                </Link>
                <Link to="/birthday" className="photos" >
                    <img className='myphoto' src={birthdayIMG} alt='фото детский день рождения'/>
                    <p className="linkPhoto">день рождения</p>
                </Link>
              
            </main>                 
        </div>       
    );
}
export default MyPhoto;