import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from "./Home";
import './App.css'
import About from "./About";
import Contact from "./Contact";
import MyPhoto from "./MyPhoto";
import Family from "./Family";
import Kids from "./Kids";
import Christ from "./Christ";
import Pregnancy from "./Pregnancy";
import Wedding from "./Wedding";
import LoveStory from "./LoveStory";
import Newborn from "./Newborn";
import Daughter from "./Daughter";
import NewYear from "./NewYear";
import Women from "./Women";
import Birthday from "./Birthday";
import Winter from "./Winter";


function App() {


  return (<Router>
  <nav> 
    <Link to="/" className="link one">Главная</Link>
    <Link to="/myPhoto" className="link">Мои работы</Link>
    <Link to="/about" className="link">Информация</Link>
    <Link to="/contact" className="link">Контакты</Link>
  </nav>
  <hr/>
  <Routes>
    
    <Route path="/" element = {<Home/>}/>
    <Route path="/myPhoto" element = {<MyPhoto/>}/>    
    <Route path="/about" element = {<About/>}/>
    <Route path="/contact" element = {<Contact/>}/>
    <Route path="/family" element = {<Family/>}/>
    <Route path="/kids" element = {<Kids/>}/> 
    <Route path="/christ" element = {<Christ/>}/> 
    <Route path="/pregnancy" element = {<Pregnancy/>}/>
    <Route path="/wedding" element = {<Wedding/>}/>   
    <Route path="/lovestory" element = {<LoveStory/>}/> 
    <Route path="/newborn" element = {<Newborn/>}/> 
    <Route path="/daughter" element = {<Daughter/>}/>
    <Route path="/newyear" element = {<NewYear/>}/>  
    <Route path="/women" element = {<Women/>}/>  
    <Route path="/birthday" element = {<Birthday/>}/>  
    <Route path="/winter" element = {<Winter/>}/> 
  </Routes>
  </Router>  
  );  
}

export default App;

