import Loader from "./Loader";

const LoaderPage = () => {
    return(
        <div className="container_loader">
            <Loader/>
        </div>
    )
}

export default LoaderPage;